import { Component, OnInit } from '@angular/core';

@Component({
	template: `<p>{{ "DEFAULT.NO_PERMISSION_DEALER'" | translate }}</p>`
})
export class NoAccessComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
