import { Request } from 'src/app/interface/request';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

import { PageOrder } from '../../interface/pageOrder/page-order';
import { ValidaPermissao } from 'src/app/interface/valida-permissao';
import { AppConstants } from 'src/app/utils/app-constants';

export class CrudService<T> {

	headers = new HttpHeaders();

	constructor(protected http: HttpClient, private baseUrl: string) { 
		this.headers = this.headers.append( 'Authorization', `Bearer ${localStorage.getItem('accessToken')}`);
	}
	
	getAllPageOrder(validaPermissao: ValidaPermissao, pageOrder: PageOrder): Observable<T> {
		const searchUrl =
			this.baseUrl +
			`findAllPage?pageNo=${pageOrder.getPageNumber}
			&pageSize=${pageOrder.getPageSize}
			&fieldOrder=${pageOrder.getSortField}
			&sortOrder=${pageOrder.getSortOrder}`;
		return this.http
			.post<T>(searchUrl , validaPermissao, { headers: this.headers})
			.pipe(take(1));
	}

	getAllPage(validaPermissao: ValidaPermissao, thePageNumber, thePageSize): Observable<T> {
		const searchUrl =
			this.baseUrl +
			`findAllPage?pageNo=${thePageNumber}&pageSize=${thePageSize}`;
		return this.http
			.post<T>(searchUrl, validaPermissao, { headers: this.headers})
			.pipe(take(1));
	}

	getFindById(validaPermissao: ValidaPermissao, record: T): Observable<T> {
		const searchUrl = this.baseUrl + `findById/?id=${record['id']}`;
		return this.http.post<T>(searchUrl, validaPermissao, { headers: this.headers})
						.pipe(take(1));
	}

	isAccessValid(validaPermissao: ValidaPermissao): Promise<any> {
		const searchUrl = this.baseUrl + `isAccessValid`;
		return this.http.put( searchUrl, validaPermissao, { headers: this.headers} ) 
						.toPromise()
						.then(data => {return data;});
	}

	/*******************/
	/*******************/
	/*******************/
	/*******************/

	/*
	*  save
	*/
	save(record: T, recNew: boolean, validaPermissao: ValidaPermissao): Observable<T> {
		const request = { payload: record, permission: validaPermissao, id: record['id'] };
		if (recNew) {
			validaPermissao.acaoBtn = AppConstants.OPER_CRUD_INSERT;
			return this.create(validaPermissao, request);
		}
		validaPermissao.acaoBtn = AppConstants.OPER_CRUD_UPDATE;
		return this.update(validaPermissao, request);
	}

	/*
	*    create
	*/
	private create(validaPermissao: ValidaPermissao,request: Request): Observable<T> {
		const httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization' : `Bearer ${localStorage.getItem('accessToken')}`
		});
		request.payload.cdsidInclC = localStorage.getItem('codUserPk');
		return this.http
			.post<T>(this.baseUrl + `save`, JSON.stringify({ request }), { headers: httpHeaders })
			.pipe(take(1));
	}

	/*
	*   update
	*/
	private update(validaPermissao: ValidaPermissao,request: Request): Observable<T> {
		const httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization' : `Bearer ${localStorage.getItem('accessToken')}`
		});
		request.payload.cdsidUltAltC = localStorage.getItem('codUserPk');
		return this.http
			.put<T>(this.baseUrl + `update`, JSON.stringify({ request }), { headers: httpHeaders })
			.pipe(take(1));
	}

	/*
	*  delete
	*/
	delete(record: T, validaPermissao: ValidaPermissao): Observable<T> {
		validaPermissao.acaoBtn = AppConstants.OPER_CRUD_DELETE;
		const request = { payload: record, permission: validaPermissao, id: record['id'] };
		const header = new HttpHeaders( {
			'Content-Type': 'application/json; charset=UTF-8',
			'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
		} )
		const httpOptions = {
			headers: header,
			body: JSON.stringify({ request })
		};
		return this.http
			.delete<T>(this.baseUrl + `delete/${record['id']}`, httpOptions)
			.pipe(take(1));
	}

}
