import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {
	ConfirmationService,
	SelectItem,
} from 'primeng/components/common/api';
import { LocaleSettings } from 'primeng/calendar';
import { ExcecaoDealer } from './excecao-dealer.model';
import { ExcecaoDealerService } from './excecao-dealer.service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { ComponentBase } from '../../../services/componentBase/component-base';
import { DealerService } from '../dealer/dealer.service';
import { LoginService } from 'src/app/oauth/login/login.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/utils/app-constants';

@Component({
	selector: 'excecao-dealer',
	templateUrl: './excecao-dealer.component.html',
	styleUrls: ['./excecao-dealer.component.scss'],
})
export class ExcecaoDealerComponent extends ComponentBase<ExcecaoDealer>
	implements OnInit {

	execTransfFlg: boolean;
	execPgtoFavFlg: boolean;
	execResgFlg: boolean;
	execSalMinFlg: boolean;
	enableVlrSalMinRest: boolean;
	execTodosFlg: boolean;

	minDateExpiracao: Date;

	statusList: SelectItem[];

	// //<!-- #################### PESQUISA DEALER ########################a -->
	colsDealer: any[];
	sortFieldDealer: string;
	pageOrderDealer: PageOrder;
	displaySearhDealer = false;
	titleSearchDealer: 'DEALER.TITLE';
	serviceDealerSearch: DealerService;
	// //<!-- #################### PESQUISA DEALER ########################a -->

	locale: LocaleSettings;

	constructor(
		protected service: ExcecaoDealerService,
		protected serviceDealer: DealerService,
		protected confirmationService: ConfirmationService,
		private cd: ChangeDetectorRef,
		protected loginService: LoginService,
		protected router: Router,
	) {
		super(service, confirmationService, loginService, router);
		this.serviceDealerSearch = serviceDealer;
	}

	ngOnInit() {

		this.onInit();

		this.sortedField = 'cdsidInclC';
		this.pageOrder = new PageOrder(0, this.pageSize, this.sortedField, AppConstants.ORDER_INDEX_ASCENDING);
		this.strSearch = '';
		this.theTotalElements = 0;
		this.displayDialog = false;

		this.execTransfFlg = false;
		this.execPgtoFavFlg = false;
		this.execResgFlg = false;
		this.execSalMinFlg = false;
		this.enableVlrSalMinRest = true;
		this.execTodosFlg = false;

		this.minDateExpiracao = new Date();

		this.colsTab = [
			{ field: 'codDealerPk'		, index: ''       , header: 'DEALER.CODIGO', mask: '', alignText: 'center' },
			{ field: 'dealerNameCAbrev'	, index: ''       , header: 'DEALER.NOME', excludeSort: 'true', mask: '', alignText: 'left' },
			{ field: 'execTransfC'		, index: ''       , header: 'EXC_DEALER.TRANSF', mask: '', alignText: 'center' },
			{ field: 'execPgtoFavC'		, index: ''       , header: 'EXC_DEALER.PAGTO_FAV', mask: '', alignText: 'center' },
			{ field: 'execResgC'		, index: ''       , header: 'EXC_DEALER.RESG', mask: '', alignText: 'center' },
			{ field: 'execSalMinC'		, index: ''       , header: 'EXC_DEALER.SALDO_MIN', mask: '', alignText: 'center' },
			{ field: 'vlrSalMinRest'	, index: ''       , header: 'EXC_DEALER.SALDO_MIN', mask: 'DATA_CURRENCY', alignText: '' },
			{ field: 'execTodosC'		, index: ''       , header: 'EXC_DEALER.TODOS', mask: '', alignText: 'center' },
		];

		this.clearRegData();

		this.clearNewRegData();

		this.statusList = [
			{ label: 'Selecione', value: '' },
			{ label: 'Ativo', value: 'S' },
			{ label: 'Inativo', value: 'N' },
		];
		//<!-- #################### PESQUISA DEALER ########################a -->
		this.cfgSearchDealer();
		//<!-- #################### PESQUISA DEALER ########################a -->
	}

	processloadRowSelect() {
		return (data) => {
			this.enableVlrSalMinRest = true;
			this.newRegData = data;
			this.execTransfFlg = this.newRegData.execTransfC === 'S' ? true : false;
			this.execPgtoFavFlg = this.newRegData.execPgtoFavC === 'S' ? true : false;
			this.execResgFlg = this.newRegData.execResgC === 'S' ? true : false;
			this.execSalMinFlg = this.newRegData.execSalMinC === 'S' ? true : false;
			this.execTodosFlg = this.newRegData.execTodosC === 'S' ? true : false;
			if (this.execSalMinFlg || this.newRegData.vlrSalMinRest > 0) {
				this.enableVlrSalMinRest = false;
			}
			this.displayDialog = true;
			this.spinnerService.progressSpinnerOff();
		};
	}

	onChangeExecSalMinC(value) {
		this.enableVlrSalMinRest = !value;
		if (!value) {
			this.newRegData.vlrSalMinRest = 0;
		}
		this.cd.detectChanges();
	}

	onChangeExecTodosC(value) {
		this.execPgtoFavFlg = value;
		this.execResgFlg = value;
		this.execTransfFlg = value;
		this.execSalMinFlg = value;
		this.onChangeExecSalMinC(value);
		if (!value) {
			this.newRegData.vlrSalMinRest = 0;
		}
		this.cd.detectChanges();
	}

	onPreSave() {
		this.newRegData.execTransfC = this.execTransfFlg ? 'S' : 'N';
		this.newRegData.execPgtoFavC = this.execPgtoFavFlg ? 'S' : 'N';
		this.newRegData.execResgC = this.execResgFlg ? 'S' : 'N';
		this.newRegData.execSalMinC = this.execSalMinFlg ? 'S' : 'N';
		this.newRegData.execTodosC = this.execTodosFlg ? 'S' : 'N';
		return true;
	}

	preClearNewRegData() {
		this.newRegData = {
			codDealerPk: null,
			dealerNameC: null,
			dealerNameCAbrev: null,
			execTransfC: null,
			execPgtoFavC: null,
			execResgC: null,
			execSalMinC: null,
			execTodosC: null,
			vlrSalMinRest: null,
			dscMotBloqC: null,
			cdSidSolC: null,
			cdsidInclC: null,
			dtInclS: null,
			cdsidUltAltC: null,
			dtUltAltS: null,
		};
		this.newRegData.cdSidSolC = this.codUserPk;
	}

	//<!-- #################### PESQUISA DEALER ########################a -->
	onSearchDealerDisplay() {
		this.displaySearhDealer = true;
	}

	onSearchDealerRowSelect(event) {
		this.newRegData.codDealerPk = event.value.codDealerPk;
		this.newRegData.dealerNameC = event.value.dealerNameC;
		this.displaySearhDealer = false;
	}

	cfgSearchDealer() {
		this.sortFieldDealer = 'dealerNameC';
		this.pageOrderDealer = new PageOrder(0, 14, this.sortFieldDealer, AppConstants.ORDER_INDEX_ASCENDING);

		this.colsDealer = [
			{ field: 'codDealerPk', index: '',         header: 'DEFAULT.CODIGO', mask: '' , alignText: 'center' },
			{ field: 'dealerNameC', index: '', 		  header: 'DEALER.NOME'   , mask: '' , alignText: 'left' },
			{ field: 'statusDescC', index: 'statusC', header: 'DEFAULT.STATUS', mask: '' , alignText: 'center' },
		];
	}

	onSearchHide(event) {
		this.displaySearhDealer = false;
	}
	//<!-- #################### PESQUISA DEALER ########################a -->
}
