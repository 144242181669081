import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
} from '@angular/core';
import {
	Message,
	LazyLoadEvent,
} from 'primeng/components/common/api';
import { empty } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { PageOrder } from '../../interface/pageOrder/page-order';
import { ErrorHandler } from 'src/app/app-error-handler';
import { OnDestroy } from '@angular/core';
import { ValidaPermissao } from 'src/app/interface/valida-permissao';
import { AppConstants } from 'src/app/utils/app-constants';

@Component({
	selector: 'searchDealer',
	templateUrl: './searchDealer.component.html',
	styleUrls: ['./searchDealer.component.scss'],
})
export class SearchDealerComponent implements OnInit {
	@Input() cols: any[];
	@Input() pageOrder: PageOrder;
	@Input() displaySearh = false;
	@Input() service: any;
	@Input() rows = 5;
	@Input() title = '';
	@Input() sortField = '';
	@Input() validaPermissao: ValidaPermissao;

	@Output() rowSelected = new EventEmitter();
	@Output() searchHide = new EventEmitter();

	strSearch = '';
	statusSearch = true;
	strStatusSearch = 'A';

	totalRecords = 0;
	loading = true;
	msgsScreenSearch: Message[] = [];
	datasource: any;
	rowSelectedValue: any;

	constructor() { }

	ngOnInit() {

	}

	loadLazy(event: LazyLoadEvent) {
		this.pageOrder.setPageNumber = event.first / event.rows;
		this.pageOrder.setPageSize = event.rows;
		for (const iterator of this.cols ) {
			if ( iterator.field === event.sortField ) {
				if ( iterator.field === event.sortField  &&  iterator.index !== '' ) {
					this.pageOrder.setSortField = iterator.index;
				} else {
					this.pageOrder.setSortField = event.sortField;
				}
			}
		}
		this.pageOrder.setSortOrder = event.sortOrder;
		this.onSearch();
	}

	load() {
		this.validaPermissao = {
			cdsidInclC: localStorage.getItem('codUserPk'),
			codDealer: parseInt(localStorage.getItem('codDn'), 10),
			codUsrC: localStorage.getItem('codUserPk'),
			ipAddressC: localStorage.getItem('ipAddress'),
			acaoBtn: AppConstants.OPER_ACESSO_A_API,
			token: localStorage.getItem('accessToken')
		};
		this.loading = true;
		this.service
			.getAllStatus(this.validaPermissao, this.strStatusSearch, this.pageOrder)
			.pipe(
				catchError(error => {
					this.messageDlg();
					this.loading = false;
					ErrorHandler.handleError(error);
					return empty();
				})
			)
			.subscribe(this.processResult());
	}

	onSearch() {
		this.strStatusSearch = 'C';
		if (this.statusSearch === true) {
			this.strStatusSearch = 'A';
		}
		if (!(this.strSearch === null || this.strSearch.length === 0)) {
			this.pageOrder.setPageNumber = 0;
			this.loadSearch();
		} else {
			this.load();
		}

	}

	loadSearch() {
		this.validaPermissao = {
			cdsidInclC: localStorage.getItem('codUserPk'),
			codDealer: parseInt(localStorage.getItem('codDn'), 10),
			codUsrC: localStorage.getItem('codUserPk'),
			ipAddressC: localStorage.getItem('ipAddress'),
			acaoBtn: AppConstants.OPER_ACESSO_A_API,
			token: localStorage.getItem('accessToken')
		};
		if (!(this.strSearch === null || this.strSearch.length === 0)) {
			this.loading = true;
			this.service
				.getSearchStatusSelecaoDealer(this.validaPermissao, this.strSearch, this.strStatusSearch, this.pageOrder)
				.pipe(
					catchError(error => {
						this.messageDlg();
						this.loading = false;
						ErrorHandler.handleError(error);
						return empty();
					})
				)
				.subscribe(this.processResult());
		}
	}

	messageDlg() {
		this.msgsScreenSearch = [];
		this.msgsScreenSearch.push({
			severity: 'error',
			summary: 'Error',
			detail:
				'Erro ao tentar carregar os Registros. Tente novamente mais tarde.',
		});
	}

	processResult() {
		return (data) => {
			this.datasource = data.content;
			this.totalRecords = data.totalElements;
			this.loading = false;
		};
	}

	onRowSelect(event) {
		this.rowSelectedValue = event.data;
		this.rowSelected.emit({ value: this.rowSelectedValue });
		this.displaySearh = false;
	}

	onCleanClose() {
		this.strSearch = null;
		this.pageOrder.setPageNumber = 0;
		this.load();
	}

	onHide() {
		this.displaySearh = false;
		this.searchHide.emit({ value: this.displaySearh });
	}

	onChangeStatus(event) {
		this.onSearch();
	}
}
