import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { TranslateService } from '@ngx-translate/core';

const EXCEL_EXTENSION = '.xlsx';
const PDF_EXTENSION = '.pdf';
const CSV_EXTENSION = '.csv';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Injectable({
	providedIn: 'root'
})

export class ExportService {
	constructor(private translate: TranslateService) {
	}

	exportPdfExec(fileName: string, objData: object[], objHeader: string[]) {
		const doc = new jsPDF('p', 'pt', 'a4');
		const header = this.objTitleToArrayTitle(objHeader);
		const dataSource = this.objDataToArrayData(objData, objHeader);
		autoTable(doc, {
			head: header,
			body: dataSource,
			didDrawPage: (dataArg) => {
				doc.text('', dataArg.settings.margin.left, 10);
			}
		});
		doc.save(fileName + '_' + new Date().getTime() + PDF_EXTENSION);
	}

	exportExcelExec(fileName: string, objData: object[], objHeader: string[]) {
		const newArray = this.changeKeyObjects(objData, objHeader);
		import('xlsx').then(xlsx => {
			const worksheet = xlsx.utils.json_to_sheet(newArray);
			const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
			const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
			this.saveAsExcelFile(excelBuffer, fileName);
		});
	}

	/**
	 * Creates an array of data to CSV. It will automatically generate a title row based on object keys.
	 *
	 * @param objArray array of data to be converted to CSV.
	 * @param fileName filename to save as.
	 * @param headerList array of object properties to convert to CSV. If skipped, then all object properties will be used for CSV.
	 */
	exportToCsvExec(fileName: string, objData: object[], objHeader: string[]) {
		const objArray = this.objDataToArrayData(objData, objHeader);
		const headerList = this.objTitleToArrayTitle(objHeader);
		const csvData = this.ConvertToCSV(objArray, headerList);
		const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
		const dwldLink = document.createElement('a');
		const url = URL.createObjectURL(blob);
		const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
		if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
			dwldLink.setAttribute('target', '_blank');
		}
		dwldLink.setAttribute('href', url);
		dwldLink.setAttribute('download', fileName + '_' + new Date().getTime() + CSV_EXTENSION);
		dwldLink.style.visibility = 'hidden';
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);

	}

	private objTitleToArrayTitle(objs: any): any {
		const arr = [];
		for (const obj of objs) {
			const xx = this.translate.instant(obj.header);
			arr.push(xx);
		}
		return [arr];
	}

	private objDataToArrayData(arrs: any, replaceKeys: any): any {
		const arr = [];
		for (const obj of arrs) {
			const dataKey = Object.keys(obj);
			const dataValue = Object.values(obj);
			const dataRet = [];
			for (const replaceKey of replaceKeys ) {
				dataRet.push(dataValue[dataKey.indexOf(replaceKey.field)]);
			}
			arr.push(dataRet);
		}
		return arr;
	}

	private changeKeyObjects(arrs: any, replaceKeys: any): any {
		const newItem = [];
		for (const arr of arrs) {
			const dataKey = Object.keys(arr);
			const dataValue = Object.values(arr);
			const dataRet = {};
			for ( const replaceKey of replaceKeys ) {
				const newHeader = this.translate.instant(replaceKey.header);
				dataRet[newHeader] = dataValue[dataKey.indexOf(replaceKey.field)];
			}
			newItem.push(dataRet);
		}
		return newItem;
	}

	private saveAsExcelFile(buffer: any, fileName: string): void {
		import('file-saver').then(fileSaver => {
			const data: Blob = new Blob([buffer], {
				type: EXCEL_TYPE
			});
			fileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
		});
	}

	private ConvertToCSV(objArray, headerList) {
		const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
		let str = '';
		let row = 'Seq.,';
		for (const header of headerList) {
			row += header + ',';
		}
		row = row.slice(0, -1);
		str += row + '\r\n';
		for (let i = 0; i < array.length; i++) {
			let line = (i + 1) + '';
			line += ',' + array[i];
			str += line + '\r\n';
		}
		return str;
	}

}
