import { LocaleService } from '../../../services/locale/locale.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {
	ConfirmationService,
	SelectItem,
} from 'primeng/components/common/api';
import { LocaleSettings } from 'primeng/calendar';

import { EmailDealer } from './email-dealer.model';
import { EmailDealerService } from './email-dealer.service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { ComponentBase } from '../../../services/componentBase/component-base';
import { DealerService } from '../dealer/dealer.service';
import { LoginService } from 'src/app/oauth/login/login.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/utils/app-constants';

@Component({
	selector: 'email-dealer',
	templateUrl: './email-dealer.component.html',
	styleUrls: ['./email-dealer.component.scss'],
})
export class EmailDealerComponent extends ComponentBase<EmailDealer>
	implements OnInit {

	statusList: SelectItem[];

	//<!-- #################### PESQUISA DEALER ########################a -->
	colsDealer: any[];
	sortFieldDealer: string;
	pageOrderDealer: PageOrder;
	displaySearhDealer = false;
	titleSearchDealer: 'DEALER.TITLE';
	serviceDealerSearch: DealerService;
	//<!-- #################### PESQUISA DEALER ########################a -->

	locale: LocaleSettings;

	constructor(
		protected service: EmailDealerService,
		protected serviceDealer: DealerService,
		protected confirmationService: ConfirmationService,
		protected loginService: LoginService,
		protected router: Router,
	) {
		super(service, confirmationService, loginService, router);
		this.serviceDealerSearch = serviceDealer;
	}

	ngOnInit() {

		this.onInit();

		this.sortedField = 'codDealerPk';
		this.pageOrder = new PageOrder(0, this.pageSize, this.sortedField, AppConstants.ORDER_INDEX_ASCENDING);
		this.strSearch = '';
		this.theTotalElements = 0;
		this.displayDialog = false;

		this.colsTab = [
			{ field: 'codDealerPk', index: ''       , header: 'DEALER.CODIGO', mask: ''  , alignText: 'center'},
			{ field: 'dealerNameC', index: ''       , header: 'DEALER.NOME', mask: ''  , alignText: 'left'},
			{ field: 'emailAbrevC', index: ''       , header: 'EMAIL_LISTA', mask: '' , alignText: 'left'},
		];

		this.clearRegData();

		this.clearNewRegData();

		//<!-- #################### PESQUISA DEALER ########################a -->
		this.cfgSearchDealer();
		//<!-- #################### PESQUISA DEALER ########################a -->

	}

	clearNewRegData() {
		this.newRegData = {
			codDealerPk: null,
			dealerNameC: null,
			emailC: null,
			emailAbrevC: null,
			cdsidInclC: null,
			dtInclS: null,
			cdsidUltAltC: null,
			dtUltAltS: null,
		};
	}

	//<!-- #################### PESQUISA DEALER ########################a -->
	onSearchDealerDisplay() {
		this.displaySearhDealer = true;
	}

	onSearchDealerRowSelect(event) {
		this.newRegData.codDealerPk = event.value.codDealerPk;
		this.newRegData.dealerNameC = event.value.dealerNameC;
		this.displaySearhDealer = false;
	}

	cfgSearchDealer() {
		this.sortFieldDealer = 'dealerNameC';
		this.pageOrderDealer = new PageOrder(0, 14, this.sortFieldDealer, AppConstants.ORDER_INDEX_ASCENDING);

		this.colsDealer = [
			{ field: 'codDealerPk', header: 'DEFAULT.CODIGO', mask: '' },
			{ field: 'dealerNameC', header: 'DEALER.NOME', mask: '' },
			{ field: 'statusC', header: 'DEFAULT.ATIVO', mask: '' },
		];
	}

	onSearchHide(event) {
		this.displaySearhDealer = false;
	}

	//<!-- #################### PESQUISA DEALER ########################a -->
}
