import { DateService } from 'src/app/services/date/date.service';
import { Component, OnInit } from '@angular/core';
import {
	ConfirmationService,
	MessageService,
	SelectItem
} from 'primeng/components/common/api';

import { Parametro } from './parametro.model';
import { ParametroService } from './parametro.service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { ComponentBase } from '../../../services/componentBase/component-base';
import { LoginService } from 'src/app/oauth/login/login.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/utils/app-constants';

@Component({
	selector: 'parametro',
	templateUrl: './parametro.component.html',
	styleUrls: ['./parametro.component.scss'],
	providers: [MessageService],
})

export class ParametroComponent extends ComponentBase<Parametro> implements OnInit {
	statusList: SelectItem[];

	dtMaxSolicTransfTmp: Date;
	minDateSolicTrans: Date;

	constructor(
		protected service: ParametroService,
		protected confirmationService: ConfirmationService,
		private dateService: DateService,
		protected loginService: LoginService,
		protected router: Router,
	) {
		super(service, confirmationService, loginService, router);
	}

	ngOnInit() {

		this.onInit();

		this.sortedField = 'id';
		this.pageOrder = new PageOrder(0, this.pageSize, this.sortedField, AppConstants.ORDER_INDEX_ASCENDING);
		this.strSearch = '';
		this.theTotalElements = 0;
		this.displayDialog = false;

		this.colsTab = [
			{ field: 'id'				, index: ''       , header: 'PARAMETRO.CODIGO', mask: '', alignText: 'center' },
			{ field: 'nmParametro'		, index: ''       , header: 'PARAMETRO.NOME', mask: '', alignText: 'left' },
			{ field: 'descParametro'	, index: ''       , header: 'PARAMETRO.DESCRICAO', mask: '', alignText: 'left' },
			{ field: 'statusDescC'		, index: 'statusC', header: 'PARAMETRO.STATUS', mask: '', alignText: 'center' },
			{ field: 'dtMaxSolicTransf'	, index: ''       , header: 'PARAMETRO.HR_MAX_SOLICITACAO_RED', mask: 'HORA_MASK', alignText: 'center' },
			{ field: 'vlrMinSolicResg'	, index: ''       , header: 'PARAMETRO.VLR_MIN_SOLICITACAO_RED', mask: 'DATA_CURRENCY', alignText: 'center' },

		];

		this.clearRegData();

		this.clearNewRegData();

		this.statusList = [
			{ label: 'Selecione', value: '' },
			{ label: 'Bloqueado', value: 'S' },
			{ label: 'Desbloqueado', value: 'N' },

		];

		this.minDateSolicTrans = new Date();

	}

	clearNewRegData() {
		this.newRegData = {
			id: null,
			nmParametro: null,
			descParametro: null,
			dtMaxSolicTransf: null,
			vlrMinSolicResg: null,
			statusC: null,
			statusDescC: null,
			cdsidInclC: null,
			dtInclS: null,
			cdsidUltAltC: null,
			dtUltAltS: null,
		};
	}

	processloadRowSelect() {
		return (data) => {
			this.newRegData = data;
			this.dtMaxSolicTransfTmp = this.dateService.stringToDate2(data.dtMaxSolicTransf, '-');
			this.displayDialog = true;
			this.spinnerService.progressSpinnerOff();
		};
	}

	onPreSave() {
		this.newRegData.dtMaxSolicTransf = this.dateService.dateTimeMask(this.dtMaxSolicTransfTmp, 'yyyy-MM-dd HH:mm:ss');
		return true;
	}

	onChangeVlrMin(event: any) {
		this.dtMaxSolicTransfTmp = this.dateService.stringToDate(this.dateService.dateTimeStartNow());
	}

	onChangeDtMaxSolic(event: any) {
		this.newRegData.vlrMinSolicResg = 0;
	}
}
