import { Component, OnInit } from '@angular/core';
import {
	ConfirmationService,
	SelectItem,
} from 'primeng/components/common/api';

import { Dealer } from './dealer.model';
import { DealerService } from './dealer.service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { ComponentBase } from '../../../services/componentBase/component-base';
import { LoginService } from 'src/app/oauth/login/login.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/utils/app-constants';

@Component({
	selector: 'dealer',
	templateUrl: './dealer.component.html',
	styleUrls: ['./dealer.component.scss'],
})
export class DealerComponent extends ComponentBase<Dealer> implements OnInit {
	statusList: SelectItem[];

	constructor(
		protected service: DealerService,
		protected confirmationService: ConfirmationService,
		protected loginService: LoginService,
		protected router: Router,
	) {
		super(service, confirmationService, loginService, router);
	}

	ngOnInit() {

		this.onInit();

		this.sortedField = 'dealerNameC';
		this.pageOrder = new PageOrder(0, this.pageSize, this.sortedField, AppConstants.ORDER_INDEX_ASCENDING);
		this.strSearch = '';
		this.theTotalElements = 0;
		this.displayDialog = false;

		this.colsTab = [
			 { field: 'codDealerPk', index: ''       , header: 'DEALER.CODIGO'	, mask: '' , alignText: 'center' },
			 { field: 'dealerNameC', index: ''       , header: 'DEALER.NOME'	, mask: '' , alignText: 'left'},
			 { field: 'matrizCode',  index: ''       , header: 'DEALER.MATRIZ'	, mask: '' , alignText: 'center'},
			 { field: 'emailC',      index: ''       , header: 'EMAIL.EMAIL'	, mask: '' , alignText: 'left'},
			 { field: 'statusDescC', index: 'statusC', header: 'DEFAULT.STATUS'	, mask: '' , alignText: 'center'},
		];

		this.clearRegData();

		this.clearNewRegData();

		this.statusList = [
			{ label: 'Selecione', value: '' },
			{ label: 'Ativo', value: 'A' },
			{ label: 'Cancelado', value: 'C' },
		];

	}

	preClearNewRegData() {
		this.newRegData = {
			codDealerPk: null,
			distrCodeC: null,
			dealerNameC: null,
			matrizCode: null,
			emailC: null,
			statusC: null,
			statusDescC: null,
			cpfCgcC: null,
		};
	}
}
