import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { take } from 'rxjs/internal/operators/take';
import { UserLogin } from './login.model';
import { Subject } from 'rxjs/internal/Subject';
import { promise } from 'protractor';
import { ValidaPermissao } from 'src/app/interface/valida-permissao';

@Injectable({
	providedIn: 'root'
})
export class LoginService {

	headers = new HttpHeaders( ); 

	constructor(private http: HttpClient) {
		this.headers = this.headers.append( 'Authorization', `Bearer ${localStorage.getItem('accessToken')}`);
	}

	dealerCod$: EventEmitter<string> = new EventEmitter();
	dealerDiv$: EventEmitter<string> = new EventEmitter();
	dealerName$: EventEmitter<string> = new EventEmitter();
	lastName$: EventEmitter<string> = new EventEmitter();
	name$: EventEmitter<string> = new EventEmitter();
	email$: EventEmitter<string> = new EventEmitter();

	usercdsid$: EventEmitter<string> = new EventEmitter();
	username$: EventEmitter<string> = new EventEmitter();
	codUserPk$: EventEmitter<string> = new EventEmitter();

	getUserToken( validaPermissao: ValidaPermissao, token: string) {
		let headers = new HttpHeaders( ); 
		headers = headers.set( 'Authorization', `Bearer ${token}`);
		const searchUrl = `${environment.apiURL}/api/v1/users/getUser`;
		return this.http
			.post<UserLogin>(searchUrl, validaPermissao, { headers: headers })
			.pipe(take(1));
	}

	getSistemaBloqueadoToken( validaPermissao: ValidaPermissao, token: string) {
		let headers = new HttpHeaders( ); 
		headers = headers.set( 'Authorization', `Bearer ${token}`);
		const searchUrl = `${environment.apiURL}/api/v1/parametros/isSistemaBloqueado`;
		return this.http
			.post<any>(searchUrl, validaPermissao, { headers: headers } )
			.pipe(take(1));

	}


}
