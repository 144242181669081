import { NoAccessComponent } from './oauth/no-access/no-access.component';
import { UrlConsumerService } from './services/Oauth/UrlConsumer/url-consumer.service';
import { OauthCallbackComponent } from './oauth/oauth-callback/oauth-callback.component';
import { LoginComponent } from './oauth/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContaCorrenteComponent } from './components/conta-corrente/conta-corrente.component';
import { ContaDealerComponent } from './components/adm/conta-dealer/conta-dealer.component';
import { DealerComponent } from './components/adm/dealer/dealer.component';
import { GrupoDealerComponent } from './components/adm/grupo-dealer/grupo-dealer.component';
import { GrupoComponent } from './components/adm/grupo/grupo.component';
import { ExcecaoDealerComponent } from './components/adm/excecao-dealer/excecao-dealer.component';
import { ContaFordComponent } from './components/adm/conta-ford/conta-ford.component';
import { EmailDealerComponent } from './components/adm/email-dealer/email-dealer.component';
import { SelecionaDealerComponent } from './components/adm/seleciona-dealer/seleciona-dealer.component';
import { AuthGuardService } from './services/Oauth/AuthGuard/auth-guard.service';

import { ParametroComponent } from './components/adm/parametro/parametro.component';
import { ControleArquivoComponent } from './components/adm/controle-arquivo/controle-arquivo.component';
import { CodigoContabilComponent } from './components/adm/codigo-contabil/codigo-contabil.component';
import { AccessComponent } from './oauth/access/access.component';
import { SistemaIndisponivelComponent as SistemaIndisponivelComponent } from './oauth/sistema-indisponivel/sistema-indisponivel.component';

const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'access',
		component: AccessComponent
	},
	{
		path: 'access_token',
		component: OauthCallbackComponent,
		canActivate: [UrlConsumerService]
	},
	{
		path: 'components/conta-corrente',
		component: ContaCorrenteComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'components/extrato',
		loadChildren: 'src/app/components/extrato/extrato.module#ExtratoModule',
		canActivate: [AuthGuardService]
	},
	{
		path: 'components/somente-consulta',
		loadChildren: 'src/app/components/somente-consulta/somente-consulta.module#SomenteConsultaModule',
		canActivate: [AuthGuardService]
	},
	/*
	// COMENTAR TODAS AS LINHAS ABAIXO
	*/
	{
		path: 'components/transferencia',
		loadChildren: 'src/app/components/transferencia-filiais/transferencia-filiais.module#TransferenciaModule',
		canActivate: [AuthGuardService]
	},
	{
		path: 'components/pagamento',
		loadChildren: 'src/app/components/transferencia-pagto-fav/transferencia-pagto-fav.module#TransferenciaPagtoFavModule',
		canActivate: [AuthGuardService]
	},
	{
		path: 'components/resgate',
		loadChildren: 'src/app/components/transferencia-resgate/transferencia-resgate.module#TransferenciaResgateModule',
		canActivate: [AuthGuardService]
	},
	/*
	// COMENTAR ATE AQUI
	*/

	{
		path: 'components/consulta',
		loadChildren: 'src/app/components/consulta/consulta.module#ConsultaModule',
		canActivate: [AuthGuardService]
	},
	{
		path: 'components/adm/banco',
		loadChildren: 'src/app/components/adm/banco/banco.module#BancoModule',
	},
	{
		path: 'components/adm/conta-dealer',
		component: ContaDealerComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'components/adm/dealer',
		component: DealerComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'components/adm/grupo-dealer',
		component: GrupoDealerComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'components/adm/grupo',
		component: GrupoComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'components/adm/excecao-dealer',
		component: ExcecaoDealerComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'components/adm/conta-ford',
		component: ContaFordComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'components/adm/email-dealer',
		component: EmailDealerComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'components/adm/seleciona-dealer',
		component: SelecionaDealerComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'components/adm/parametro',
		component: ParametroComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'components/adm/controle-arquivo',
		component: ControleArquivoComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'components/adm/codigo-contabil',
		component: CodigoContabilComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'no-access',
		component: NoAccessComponent
	},
	{
		path: 'sistema-indisponivel',
		component: SistemaIndisponivelComponent
	},
	{
		path: 'components/adm/email',
		loadChildren: 'src/app/components/adm/email/email.module#EmailModule',
	},
	{
		path: 'components/adm/acesso-invalido',
		loadChildren: 'src/app/components/adm/acesso-invalido/acesso-invalido.module#AcessoInvalidoModule',
	},
	{
		path: 'components/adm/acesso',
		loadChildren: 'src/app/components/adm/acesso/acesso.module#AcessoModule',
	},
	{
		path: 'components/adm/controle-documento',
		loadChildren: 'src/app/components/adm/controle-documento/controle-documento.module#ControleDocumentoModule',
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
