import { Component, OnInit } from '@angular/core';
import {
	ConfirmationService,
	SelectItem
} from 'primeng/components/common/api';

import { Grupo } from './grupo.model';
import { GrupoService } from './grupo.service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { ComponentBase } from '../../../services/componentBase/component-base';
import { LoginService } from 'src/app/oauth/login/login.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/utils/app-constants';

@Component({
	selector: 'grupo',
	templateUrl: './grupo.component.html',
	styleUrls: ['./grupo.component.scss']
})

export class GrupoComponent extends ComponentBase<Grupo> implements OnInit {

	statusList: SelectItem[];

	constructor(
		protected service: GrupoService,
		protected confirmationService: ConfirmationService,
		protected loginService: LoginService,
		protected router: Router,
	) {
		super(service, confirmationService, loginService, router);
	}

	ngOnInit() {

		this.onInit();

		this.sortedField = 'nmGrpC';
		this.pageOrder = new PageOrder(0, this.pageSize, this.sortedField, AppConstants.ORDER_INDEX_ASCENDING);
		this.strSearch = '';
		this.theTotalElements = 0;
		this.displayDialog = false;

		this.colsTab = [
			{ field: 'nmGrpC' 		, index: ''       , header: 'GRUPO.GRUPO', mask: '', alignText: 'center' },
			{ field: 'statusDescC'	, index: 'statusC', header: 'DEFAULT.STATUS', mask: '' , alignText: 'center'},
		];

		this.clearRegData();

		this.clearNewRegData();

		this.statusList = [
			{ label: 'Selecione', value: '' },
			{ label: 'Ativo', value: 'S' },
			{ label: 'Cancelado', value: 'N' },
		];

	}

	preClearNewRegData() {
		this.newRegData = {
			id: null,
			nmGrpC: null,
			statusC: null,
			statusDescC: null,
			cdsidInclC: null,
			dtInclS: null,
			cdsidUltAltC: null,
			dtUltAltS: null,
		};
	}

}
