
import { UrlConsumerService } from './services/Oauth/UrlConsumer/url-consumer.service';
import { AuthGuardService } from './services/Oauth/AuthGuard/auth-guard.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, Injector } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { CardModule } from 'primeng/card';
import { TabViewModule } from 'primeng/tabview';
import { PanelModule } from 'primeng/panel';

import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { GrowlModule } from 'primeng/growl';
import { SidebarModule } from 'primeng/sidebar';
import { MessageService } from 'primeng/components/common/messageservice';
import { AppRoutingModule } from './app-routing.module';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputMaskModule } from 'primeng/inputmask';
// import en from '@angular/common/locales/en';
// import es from '@angular/common/locales/es';
import pt from '@angular/common/locales/pt';
import { registerLocaleData, DatePipe } from '@angular/common';

import { ContaCorrenteComponent } from './components/conta-corrente/conta-corrente.component';
import { ContaCorrenteService } from './components/conta-corrente/conta-corrente.service';
import { ContaDealerComponent } from './components/adm/conta-dealer/conta-dealer.component';
import { ContaDealerService } from './components/adm/conta-dealer/conta-dealer.service';
import { DealerService } from './components/adm/dealer/dealer.service';
import { DealerComponent } from './components/adm/dealer/dealer.component';
import { SearchDealerComponent } from './shared/searchDealer/searchDealer.component';
import { GrupoDealerComponent } from './components/adm/grupo-dealer/grupo-dealer.component';
import { SearchGrupoComponent } from './shared/searchGrupo/searchGrupo.component';
import { GrupoComponent } from './components/adm/grupo/grupo.component';
import { GrupoService } from './components/adm/grupo/grupo.service';
import { ExcecaoDealerComponent } from './components/adm/excecao-dealer/excecao-dealer.component';
import { ExcecaoDealerService } from './components/adm/excecao-dealer/excecao-dealer.service';
import { CheckboxModule } from 'primeng/checkbox';
import { LocaleService } from './services/locale/locale.service';
import { LoginComponent } from './oauth/login/login.component';
import { OauthCallbackComponent } from './oauth/oauth-callback/oauth-callback.component';
import { NoAccessComponent } from './oauth/no-access/no-access.component';
import { ContaFordComponent } from './components/adm/conta-ford/conta-ford.component';
import { ContaFordService } from './components/adm/conta-ford/conta-ford.service';
import { EmailDealerComponent } from './components/adm/email-dealer/email-dealer.component';
import { EmailDealerService } from './components/adm/email-dealer/email-dealer.service';
import { SelecionaDealerComponent } from './components/adm/seleciona-dealer/seleciona-dealer.component';
import { SelecionaDealerService } from './components/adm/seleciona-dealer/seleciona-dealer.service';
import { SistemaIndisponivelComponent } from './oauth/sistema-indisponivel/sistema-indisponivel.component';

import { ParametroComponent } from './components/adm/parametro/parametro.component';
import { ParametroService } from './components/adm/parametro/parametro.service';
import { ControleArquivoComponent } from './components/adm/controle-arquivo/controle-arquivo.component';
import { ControleArquivoService } from './components/adm/controle-arquivo/controle-arquivo.service';
// import { ExtratoComponent } from './components/extrato/extrato.component';:
import { CodigoContabilComponent } from './components/adm/codigo-contabil/codigo-contabil.component';
import { AccessComponent } from './oauth/access/access.component';
import { IpService } from './services/ip/ip.service';
import { LocatorService } from './services/locator/locator.service';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

// registerLocaleData(en, 'en');
// registerLocaleData(es, 'es');
registerLocaleData(pt, 'pt');

@NgModule({
	declarations: [
		AppComponent,
		NavComponent,
		ContaCorrenteComponent,
		ContaDealerComponent,
		DealerComponent,
		SearchDealerComponent,
		GrupoDealerComponent,
		LoginComponent,
		OauthCallbackComponent,
		NoAccessComponent,
		SistemaIndisponivelComponent,
		SearchGrupoComponent,
		GrupoComponent,
		ExcecaoDealerComponent,
		ContaFordComponent,
		EmailDealerComponent,
		SelecionaDealerComponent,
		ParametroComponent,
		ControleArquivoComponent,
		// ExtratoComponent,
		CodigoContabilComponent,
		AccessComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		AppRoutingModule,
		ButtonModule,
		MenubarModule,
		PanelMenuModule,
		GrowlModule,
		SidebarModule,
		MessagesModule,
		MessageModule,
		CardModule,
		TabViewModule,
		PanelModule,
		TableModule,
		HttpClientModule,
		CalendarModule,
		FormsModule,
		DropdownModule,
		ConfirmDialogModule,
		DialogModule,
		AutoCompleteModule,
		BreadcrumbModule,
		InputTextModule,
		InputTextareaModule,
		ProgressSpinnerModule,
		TooltipModule,
		KeyFilterModule,
		InputMaskModule,
		CheckboxModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
	],
	providers: [
		MessageService,
		ContaCorrenteService,
		ConfirmationService,
		ContaDealerService,
		DealerService,
		LocaleService,
		ContaFordService,
		GrupoService,
		ExcecaoDealerService,
		EmailDealerService,
		SelecionaDealerService,
		DatePipe,
		{
			provide: LOCALE_ID,
			useValue: 'pt-BR'
		},
		// {
		// 	provide: LOCALE_ID,
		//   	deps: [LocaleService],
		//   	useFactory: (localeService) => localeService.getLocale(),
		// },
		AuthGuardService,
		UrlConsumerService,
		ParametroService,
		ControleArquivoService,
		IpService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(private injector: Injector) {
		LocatorService.injector = this.injector;
	}
}
