import { LoginService } from './../../../oauth/login/login.service';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router
} from '@angular/router';
import { isNullOrUndefined } from 'util';
import { MenuService } from '../../menu/menu.service';
import { Menu } from '../../menu/menu';

@Injectable({
	providedIn: 'root'
})
export class AuthGuardService {
	[x: string]: any;
	tokenExp: number;
	username: string;

	constructor(
		private router: Router,
		private menuService: MenuService,
		private loginService: LoginService,
	) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {

		if (this.checkLogin(state.url)) {
			return true;
		}
		this.desableMenu();
		return false;
	}

	desableMenu() {
		this.menuService.setMenuSubject(new Menu(false, false));
	}

	parselocalStorage(url: string) {
		if (localStorage.length > 0) {
			localStorage['redirectURL'] = url;
			this.tokenExp = localStorage['tokenExp'];
			// this.username = localStorage['username'];
			this.username = localStorage.getItem('codUserPk');
			
			this.loginService.username$.emit(localStorage.getItem('codUserPk'));
			this.loginService.dealerDiv$.emit('2');
			this.loginService.lastName$.emit(localStorage['last_name']);
			this.loginService.name$.emit(localStorage['name']);
			this.loginService.email$.emit(localStorage['email']);

			//this.loginService.dealerCod$.emit(localStorage.getItem('codDn'));
			//this.loginService.dealerName$.emit(localStorage.getItem('nomeC'));


		} else {
			this.router.navigate(['/login']);
		}
	}

	isTokenExpired(): boolean {
		const epoch = Math.trunc(new Date().getTime() / 1000);
		if (typeof this.tokenExp === 'undefined') {
			return true;
		}
		return epoch < this.tokenExp ? false : true;
	}

	checkLogin(url: string): boolean {

		if (environment.inmem) {
			return true;
		}
		this.parselocalStorage(url);
		const isTokenExpired = this.isTokenExpired();

		if (isTokenExpired) {
			localStorage.clear();
			this.desableMenu();
			this.router.navigate(['/login']);
			return false;
		}

		return true;
	}

}
