import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/components/common/messageservice';
import { empty } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { ErrorHandler } from 'src/app/app-error-handler';
import { ContaCorrenteService } from '../conta-corrente/conta-corrente.service';
import { ContaCorrente } from '../conta-corrente/conta-corrente.model';
import { ContaCorrenteResumo } from '../conta-corrente/conta-corrente-resumo.model';
import { Base } from 'src/app/services/base/base';
import { DropDownDealerFav } from 'src/app/interface/dropdown-dealer-fav.model';
import { AppConstants } from 'src/app/utils/app-constants';

@Component({
	selector: 'conta-corrente',
	templateUrl: './conta-corrente.component.html',
	styleUrls: ['./conta-corrente.component.scss'],
	providers: [MessageService],
})
export class ContaCorrenteComponent extends Base implements OnInit {

	dtInicioStr: string;
	dtInicio: Date;
	nroDias: number;

	saldoConta = 0;
	loadedRegUltimosLancamentos: boolean;
	tabViewSelectedIndex: number;

	displayDialogCheckSaldo: boolean;

	datasourceUltLancto: ContaCorrente[];
	datasourceProxLancto: ContaCorrente[];

	colsTab: any[];

	vlrTotalCred: number;
	vlrTotalDeb: number;
	sortedField: string;

	ctaCorrenteResumo: ContaCorrenteResumo;

	dealerDropDownSelected: DropDownDealerFav;
	dealerDropDown: DropDownDealerFav[];

	constructor(
		private contaCorrenteService: ContaCorrenteService,
	) {

		super(contaCorrenteService);

		this.loginService.dealerCod$.subscribe(dealerCod => this.codDealer = dealerCod);
	}

	ngOnInit() {

		this.onInit();

		this.tabViewSelectedIndex = 0;
		this.nroDias = AppConstants.NRO_DIAS_INICIAL;

		this.dtInicio = new Date();
		this.dtInicio.setHours(0, 0, 0, 0);
		this.dtInicioStr = this.dateTimeService.dateNow();
		this.loadedRegUltimosLancamentos = false;
		this.clearRegData();
		this.vlrTotalCred = 0;
		this.vlrTotalDeb = 0;

		this.colsTab = [
			{ field: 'codDealer', header: 'CTA_CORR_LCTO.DEALER', mask: '', alignText: 'center' },
			{ field: 'dtMovto', header: 'CTA_CORR_LCTO.DATA', mask: '' , alignText: 'center'},
			{ field: 'nroDocC', header: 'CTA_CORR_LCTO.DOCUMENTO', mask: '' , alignText: 'center'},
			{ field: 'codContabil', header: 'CTA_CORR_LCTO.COD_CONTABIL', mask: '' , alignText: 'center'},
			{ field: 'dscContabilC', header: 'CTA_CORR_LCTO.DSC_CONTABIL', mask: '' , alignText: 'left'},
			{ field: 'vlrLctoDeb', header: 'CTA_CORR_LCTO.DEBITO', mask: 'DATA_CURRENCY' , alignText: ''},
			{ field: 'vlrLctoCred', header: 'CTA_CORR_LCTO.CREDITO', mask: 'DATA_CURRENCY' , alignText: ''},
			{ field: 'vlrLcto', header: 'CTA_CORR_LCTO.SALDO', mask: 'DATA_CURRENCY' , alignText: ''},

		];

		this.dealerDropDownSelected = {
			codDealer: this.codDealer,
			nmDealer: this.dealerName
		};

		this.displayDialogCheckSaldo = false;

	}

	onLoad(displayDialogCheck: boolean) {

		this.displayDialogCheckSaldo = (!this.dealerCheck.isExisteLancamentoSaldoCc || !this.dealerCheck.isExisteLancamentoSaldoCp);

		// se não tiver saldo de CP ou CC , não carrega o resumo da conta corrente
		if (!this.displayDialogCheckSaldo) {

			this.spinnerService.progressSpinnerOn();

			// carrega os Dealer pelo grupo do dealer
			this.loadDropDownDealersByGrupoDealer();

			this.dealerDropDownSelected = {
				codDealer: this.codDealer,
				nmDealer: this.dealerName
			};

			this.spinnerService.progressSpinnerOff();

			this.loadData();

		}

	}

	loadData() {
		//  CC Resumo
		this.loadCCResumo();

		// load Últimos Lançamentos
		this.loadUltLancto();
	}

	/*****************************************************************************/
	/*****************************************************************************/
	/*****************************************************************************/
	/*****************************************************************************/
	/*****************************************************************************/
	/*****************************************************************************/
	/*****************************************************************************/
	/*****************************************************************************/
	/*
	 *
	 *
	 *
	 ***/
	loadDropDownDealersByGrupoDealer() {
		this.validaPermissao = {
			cdsidInclC: localStorage.getItem('codUserPk'),
			codDealer: parseInt(localStorage.getItem('codDn'), 10),
			codUsrC: localStorage.getItem('codUserPk'),
			ipAddressC: localStorage.getItem('ipAddress'),
			acaoBtn: AppConstants.OPER_ACESSO_A_API,
			token: localStorage.getItem('accessToken')
		};
		this.spinnerService.progressSpinnerOn();
		this.contaCorrenteService
			.getDropDownDealersByGrupoDealer(this.validaPermissao, this.codDealer)
			.pipe(
				catchError(error => {
					this.spinnerService.progressSpinnerOff();
					this.msgsDlgNewReg = [];
					this.msgsDlgNewReg.push( ErrorHandler.messageDlgErroNew(error, 'Erro ao carregar Dealer pelo grupo do dealer'));
					ErrorHandler.handleError(error);
					return empty();
				})
			)
			.subscribe(this.processLoadDropDownDealersByGrupoDealer());
	}

	processLoadDropDownDealersByGrupoDealer() {
		return (data) => {
			this.dealerDropDown = data;
			this.spinnerService.progressSpinnerOff();
		};
	}

	/*
	 *
	 *
	 *
	 ***/
	onChangeDrop(evento) {

		this.dealerDropDownSelected = {
			codDealer: evento.value.codDealer,
			nmDealer: evento.value.nmDealer
		};

		this.tabViewSelectedIndex = 0;

		this.loadData();
	}

	/*
	 *
	 *
	 *
	 ***/
	onChangeTabView($event) {
		this.tabViewSelectedIndex = $event.index;
	}

	/*****************************************************************************/
	/*****************************************************************************/
	/*****************************************************************************/
	/*****************************************************************************/
	/*****************************************************************************/
	/*****************************************************************************/
	/*****************************************************************************/
	/*****************************************************************************/
	/*
	 *
	 *   CC Resumo
	 *
	 ***/
	loadCCResumo() {
		this.validaPermissao = {
			cdsidInclC: localStorage.getItem('codUserPk'),
			//codDealer: this.dealerDropDownSelected.codDealer,
			codDealer: parseInt(localStorage.getItem('codDn'), 10),
			codUsrC: localStorage.getItem('codUserPk'),
			ipAddressC: localStorage.getItem('ipAddress'),
			acaoBtn: AppConstants.OPER_ACESSO_A_API,
			token: localStorage.getItem('accessToken')
		};
		this.spinnerService.progressSpinnerOn();
		this.contaCorrenteService
			.getResumoByDealerDtMovimento(this.validaPermissao, this.dealerDropDownSelected.codDealer, this.dateTimeService.dateTimeStartNow())
			.pipe(
				catchError(error => {
					this.spinnerService.progressSpinnerOff();
					this.msgsDlgNewReg = [];
					this.msgsDlgNewReg.push( ErrorHandler.messageDlgErroNew(error, 'Carga do resumo'));
					ErrorHandler.handleError(error);
					return empty();
				})
			)
			.subscribe(this.processCCResumoResult());
	}

	processCCResumoResult() {
		return (data) => {
			this.ctaCorrenteResumo = data;
			this.spinnerService.progressSpinnerOff();
		};
	}

	clearRegData() {
		this.ctaCorrenteResumo = {
			id: null,
			codDealer: null,
			nmDealerC: null,
			dtMovto: null,
			vlrVenctosEmAtraso: null,
			vlrEncargosDia: null,
			vlrVencidosDia: null,
			vlrSaldoDisponivel: null,
			vlrSolicDeTransf: null,
			vlrPagamentoFav: null,
			vlrSolicDeResgate: null,
			vlrTrasnfRecebidas: null,
			vlrSaldoMinimoBloqueado: null,
			vlrSomaTransferencias: null,
			vlrSomaResgates: null,
			vlrSaldoFinal: null,
			vlrSaldoAnterior: null,
		};
	}

	/*****************************************************************************/
	/*****************************************************************************/
	/*****************************************************************************/
	/*****************************************************************************/
	/*****************************************************************************/
	/*****************************************************************************/
	/*****************************************************************************/
	/*****************************************************************************/
	/*
	 *
	 *   CC Ultimos Lancamentos
	 *
	 ***/
	loadUltLancto() {
		this.validaPermissao = {
			cdsidInclC: localStorage.getItem('codUserPk'),
			//codDealer: this.dealerDropDownSelected.codDealer,
			codDealer: parseInt(localStorage.getItem('codDn'), 10),
			codUsrC: localStorage.getItem('codUserPk'),
			ipAddressC: localStorage.getItem('ipAddress'),
			acaoBtn: AppConstants.OPER_ACESSO_A_API,
			token: localStorage.getItem('accessToken')
		};

		this.vlrTotalCred = 0;
		this.vlrTotalDeb = 0;
		this.spinnerService.progressSpinnerOn();
		this.loadedRegUltimosLancamentos = false;
		this.contaCorrenteService
			.getLancamentosByDealerDtMovto(this.validaPermissao, this.dealerDropDownSelected.codDealer, this.dateTimeService.dateTime(this.dateTimeService.getMinusDay(this.dtInicio, this.nroDias)), this.dateTimeService.dateTimeEndNow(), AppConstants.TP_LANCAMENTOS_TODOS)
			.pipe(
				catchError(error => {
					this.spinnerService.progressSpinnerOff();
					this.msgsDlgNewReg = [];
					this.msgsDlgNewReg.push( ErrorHandler.messageDlgErroNew(error, 'carga ultimos lançamentos'));
					ErrorHandler.handleError(error);
					return empty();
				})
			)
			.subscribe(this.processtLancamentosByDealerDtMovto());
	}

	processtLancamentosByDealerDtMovto() {
		return (data) => {
			this.datasourceUltLancto = data;
			this.saldoConta = data.vlrLcto;
			this.loadedRegUltimosLancamentos = true;
			for (const iterator of data) {
				this.vlrTotalCred += iterator.vlrLctoCred;
				this.vlrTotalDeb += iterator.vlrLctoDeb;
			}
			this.spinnerService.progressSpinnerOff();
		};
	}

}
