import { Component, OnInit } from '@angular/core';
import {
	ConfirmationService,
	SelectItem,
} from 'primeng/components/common/api';

import { GrupoDealer } from './grupo-dealer.model';
import { GrupoDealerService } from './grupo-dealer.service';
import { GrupoService } from '../grupo/grupo.service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { ComponentBase } from '../../../services/componentBase/component-base';
import { DealerService } from '../dealer/dealer.service';
import { LoginService } from 'src/app/oauth/login/login.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/utils/app-constants';

@Component({
	selector: 'grupoDealer',
	templateUrl: './grupo-dealer.component.html',
	styleUrls: ['./grupo-dealer.component.scss'],
})
export class GrupoDealerComponent extends ComponentBase<GrupoDealer>
	implements OnInit {
	statusList: SelectItem[];

	// //<!-- #################### PESQUISA DEALER ########################a -->
	colsDealer: any[];
	sortFieldDealer = 'dealerNameC';
	pageOrderDealer: PageOrder;
	displaySearhDealer = false;
	titleSearchDealer = 'GRP_DEALER.TITLE';
	serviceDealerSearch: DealerService;
	// //<!-- #################### PESQUISA DEALER ########################a -->

	// //<!-- #################### PESQUISA GRUPO ########################a -->
	colsGrupo: any[];
	sortFieldGrupo = 'nmGrpC';
	pageOrderGrupo: PageOrder;
	displaySearhGrupo = false;
	titleSearchGrupo = 'GRUPO.TITLE';
	serviceGrupoSearch: GrupoService;
	// //<!-- #################### PESQUISA GRUPO ########################a -->

	constructor(
		protected service: GrupoDealerService,
		protected serviceDealer: DealerService,
		protected serviceGrupo: GrupoService,
		protected confirmationService: ConfirmationService,
		protected loginService: LoginService,
		protected router: Router,
	) {
		super(service, confirmationService, loginService, router);
		this.serviceDealerSearch = serviceDealer;
		this.serviceGrupoSearch = serviceGrupo;
	}

	ngOnInit() {

		this.onInit();

		this.sortedField = 'cdsidInclC';
		this.pageOrder = new PageOrder(0, this.pageSize, this.sortedField, AppConstants.ORDER_INDEX_ASCENDING);
		this.strSearch = '';
		this.theTotalElements = 0;
		this.displayDialog = false;

		this.colsTab = [
			{ field: 'nmGrpC'		, index: 'grupoBO.nmGrpC' 				, header: 'GRP_DEALER.NOME'	, mask: '' , alignText: 'center'},
			{ field: 'codDealerPk'	, index: 'grupoDealerBOPK.codDealerPk'	, header: 'DEALER.CODIGO'	, mask: '' , alignText: 'center'},
			{ field: 'dealerNameC'	, index: ''       						, header: 'DEALER.NOME'		, excludeSort: 'true', mask: '' , alignText: 'left'},
			{ field: 'statusDescC'	, index: 'statusC'						, header: 'DEFAULT.STATUS'	, mask: '' , alignText: 'center'},
		];

		this.clearRegData();

		this.clearNewRegData();

		this.statusList = [
			{ label: 'Selecione', value: '' },
			{ label: 'Ativo', value: 'S' },
			{ label: 'Inativo', value: 'N' },
		];
		//<!-- #################### PESQUISA DEALER ########################a -->
		this.cfgSearchDealer();
		//<!-- #################### PESQUISA DEALER ########################a -->

		//<!-- #################### PESQUISA GRUPO ########################a -->
		this.cfgSearchGrupo();
		//<!-- #################### PESQUISA GRUPO ########################a -->
	}

	preClearNewRegData() {
		this.newRegData = {
			grpIdPk: null,
			codDealerPk: null,
			dealerNameC: null,
			nmGrpC: null,
			statusC: null,
			statusDescC: null,
			cdsidInclC: null,
			dtInclS: null,
			cdsidUltAltC: null,
			dtUltAltS: null,
		};
	}

	//<!-- #################### PESQUISA DEALER ########################a -->
	onSearchDealerDisplay() {
		this.displaySearhDealer = true;
	}

	onSearchDealerRowSelect(event) {
		this.newRegData.codDealerPk = event.value.codDealerPk;
		this.newRegData.dealerNameC = event.value.dealerNameC;
		this.displaySearhDealer = false;
	}

	cfgSearchDealer() {
		this.pageOrderDealer = new PageOrder(0, 14, 'dealerNameC', AppConstants.ORDER_INDEX_ASCENDING);

		this.colsDealer = [
			{ field: 'codDealerPk', header: 'DEFAULT.CODIGO', mask: '' },
			{ field: 'dealerNameC', header: 'DEALER.NOME', mask: '' },
			{ field: 'statusC', header: 'DEFAULT.ATIVO', mask: '' },
		];
	}

	onSearchHide(event) {
		this.displaySearhDealer = false;
	}
	//<!-- #################### PESQUISA DEALER ########################a -->

	//<!-- #################### PESQUISA GRUPO ########################a -->
	onSearchGrupoDisplay() {
		this.displaySearhGrupo = true;
	}

	onSearchGrupoRowSelect(event) {
		this.newRegData.grpIdPk = event.value.id;
		this.newRegData.nmGrpC = event.value.nmGrpC;
		this.displaySearhGrupo = false;
	}

	cfgSearchGrupo() {
		this.pageOrderGrupo = new PageOrder(0, 14, 'nmGrpC', AppConstants.ORDER_INDEX_ASCENDING);
		this.colsGrupo = [
			{ field: 'id', header: 'GRUPO.CODIGO', mask: '' },
			{ field: 'nmGrpC', header: 'GRUPO.GRUPO', mask: '' },
			{ field: 'statusC', header: 'DEFAULT.STATUS', mask: '' },
			{ field: 'cdsidInclC', header: 'DEFAULT.USR_INCL', mask: '' },
			{ field: 'dtInclS', header: 'DEFAULT.DT_INCL', mask: '' },
		];
	}

	onSearchGrupoHide(event) {
		this.displaySearhGrupo = false;
	}

	//<!-- #################### PESQUISA GRUPO ########################a -->
}
