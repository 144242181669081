import { Component, OnInit } from '@angular/core';
import {
	ConfirmationService,
	MessageService,
	SelectItem
} from 'primeng/components/common/api';

import { ControleArquivo } from './controle-arquivo.model';
import { ControleArquivoService } from './controle-arquivo.service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { ComponentBase } from '../../../services/componentBase/component-base';
import { LoginService } from 'src/app/oauth/login/login.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/utils/app-constants';

@Component({
	selector: 'controlearquivo',
	templateUrl: './controle-arquivo.component.html',
	styleUrls: ['./controle-arquivo.component.scss'],
	providers: [MessageService],
})

export class ControleArquivoComponent extends ComponentBase<ControleArquivo> implements OnInit {

	statusList: SelectItem[];

	constructor(
		protected service: ControleArquivoService,
		protected confirmationService: ConfirmationService,
		protected loginService: LoginService,
		protected router: Router,
	) {
		super(service, confirmationService, loginService, router);
	}

	ngOnInit() {

		this.onInit();

		this.sortedField = 'id';
		this.pageOrder = new PageOrder(0, this.pageSize, this.sortedField, AppConstants.ORDER_INDEX_ASCENDING);
		this.strSearch = '';
		this.theTotalElements = 0;
		this.displayDialog = false;

		this.colsTab = [
			{ field: 'id'			, index: ''       , header: 'CTRL_ARQUIVO.CODIGO', mask: '' , alignText: 'center'},
			{ field: 'nmArquivoC'	, index: ''       , header: 'CTRL_ARQUIVO.NOME', mask: '', alignText: 'left' },
			{ field: 'descArquivoC'	, index: ''       , header: 'CTRL_ARQUIVO.DESCRICAO', mask: '', alignText: 'left' },
			{ field: 'sequencia'	, index: ''       , header: 'CTRL_ARQUIVO.SEQUENCIA', mask: '', alignText: 'center'},
			{ field: 'statusDescC'	, index: 'statusC', header: 'DEFAULT.STATUS', mask: '', alignText: 'center' },
			{ field: 'cdsidUltAltC'	, index: ''       , header: 'CTRL_ARQUIVO.USU_ALTERACAO', mask: '', alignText: 'center' },
			{ field: 'dtUltAltS'	, index: ''       , header: 'CTRL_ARQUIVO.DT_ALTERACAO', mask: '', alignText: 'center' },
		];

		this.clearRegData();

		this.clearNewRegData();

		this.statusList = [
			{ label: 'Selecione', value: '' },
			{ label: 'Ativo', value: 'A' },
			{ label: 'Inativo', value: 'I' },
		];

	}

	clearNewRegData() {
		this.newRegData = {
			id: null,
			nmArquivoC: null,
			descArquivoC: null,
			cabecalhoC: null,
			rodapeC: null,
			sequencia: null,
			statusC: null,
			statusDescC: null,
			cdsidInclC: null,
			dtInclS: null,
			cdsidUltAltC: null,
			dtUltAltS: null,
		};
	}

}
